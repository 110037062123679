import { ChangeEvent, useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createAvailableDayData,
  getListVariationByProduct,
  resetSingle,
  resetStatus,
  uploadPhoto,
} from "../voucher.reducer";
import { RootState } from "../../../../app/store";
import AlertMessage from "../../../../components/alertMessage";
import { AvailableDayModel, VoucherModel } from "../voucher.model";
import { DatePicker, Select, Switch } from "antd";
import moment from "moment";
import upload from "./../../../../assets/vector/upload.png";
import { convertBase64 } from "../../../../components/Library/Base64";
import Dropzone from "react-dropzone";
import { endpoint } from "../../../../constants";
import { DragSwitch } from "react-dragswitch";
import NumberFormat from "react-number-format";

const VoucherForm = ({
  data = {} as VoucherModel,
  isEdit = false,
  ...props
}: VoucherFormProps) => {
  let { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  const roleIdList = useSelector(
    (state: RootState) => state.voucher.selectedRoleList ?? ""
  );

  const membershipTierList = useSelector(
      (state: RootState) => state.voucher.selectedMembershipTierList ?? ""
  );

  const imageState = useSelector(
    (state: RootState) => state.voucher.imagePath ?? []
  );

  const voucherIdState = useSelector(
    (state: RootState) => state.voucher.voucherId ?? ""
  );

  const listAvailableDayState = useSelector(
    (state: RootState) => state.voucher.availableDay ?? []
  );

  const isEnableStore = useSelector(
    (state: RootState) => state.voucher.enableStore ?? false
  );

  const createStatus = useSelector(
    (state: RootState) => state.voucher.status ?? ""
  );

  const errorMessage = useSelector(
    (state: RootState) => state.voucher.error ?? ""
  );

  const productState = useSelector(
    (state: RootState) => state.global.listProduct ?? []
  );

  const variantState = useSelector(
    (state: RootState) => state.voucher.variantListByProduct ?? []
  )

  const collectionTagState = useSelector(
    (state: RootState) => state.global.listCollectionTag ?? []
  );

  const cityState = useSelector(
    (state: RootState) => state.global.listCity ?? []
  );

  let history = useHistory();
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState(0);
  const [appliesMultiple, setAppliesMultiple] = useState(false);
  const [isMembership, setIsMembership] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [discountValue, setDiscountValue] = useState(0);
  const [maximumAmount, setMaximumAmount] = useState(0);
  const [valueType, setValueType] = useState(0);
  const [isEntireOrder, setIsEntireOrder] = useState(false);
  const [isSpecificCollection, setIsSpecificCollection] = useState(true);
  const [collectionListId, setCollectionListId] = useState("");
  const [productListId, setProductListId] = useState("");
  const [isNoneMinimumRequirement, setIsNoneMinimumRequirement] =
    useState(true);
  const [isMinimumPurchaseAmount, setIsMinimumPurchaseAmount] = useState(true);
  const [minimumPurchaseAmount, setMinimumPurchaseAmount] = useState(0);
  const [isMinimumQuantityOfItems, setIsMinimumQuantityOfItems] =
    useState(true);
  const [isNumberOfUsage, setIsNumberOfUsage] = useState(false);
  const [isLimitUsePerCustomer, setIsLimitUsePerCustomer] = useState(false);
  const [isAllCity, setIsAllCity] = useState(true);
  const [isSelectedCity, setIsSelectedCity] = useState(true);
  const [selectedCity, setSelectedCity] = useState("");
  const [isExcludeShipping, setIsExcludeShipping] = useState(false);
  const [excludeShipping, setExcludeShipping] = useState(0);
  const [customerGetAmountQty, setCustomerGetAmountQty] = useState(0);
  const [anyItemFrom, setAnyItemFrom] = useState(0);
  const [isFree, setIsFree] = useState(true);
  const [isNominalCashback, setIsNominalCashback] = useState(true);
  const [nominalCashback, setNominalCashback] = useState(0);
  const [isMaximumAmountPercentage, setIsMaximumAmountPercentage] =
    useState(true);
  const [maximumAmountPercentage, setMaximumAmountPercentage] = useState(0);
  const [isUpto, setIsUpto] = useState(true);
  const [upto, setUpto] = useState(0);
  const [quota, setQuota] = useState(0);
  const [maximumEstimatedStoreExpenses, setMaximumEstimatedStoreExpenses] =
    useState(0);
  const [numberOfUsage, setNumberOfUsage] = useState(0);
  const [isSpecificProduct, setIsSpecificProduct] = useState(true);
  const [discountMinimumRequirementType, setDiscountMinimumRequirementType] =
    useState(0);
  const [isActive, setIsActive] = useState(true);
  const [isHidden, setIsHidden] = useState<boolean>(false)
  const [isExpired, setIsExpired] = useState(false);
  const [freeItemId, setFreeItemId] = useState("");
  const [productId, setProductId] = useState("")
  const [productFreeCode, setProductFreeCode] = useState<any[] | null>(null);
  const [nominalCashbackType, setNominalCashbackType] = useState(0);
  const [minimumQuantityOfItems, setMinimumQuantityOfItems] = useState(0);

  const [discountCashbackAmountPercentage, setDiscountCashbackAmountPercentage] = useState(0)

  //form validation
  const [validated, setValidated] = useState(false);

  //helper
  const [productCode, setProductCode] = useState<any[] | null>(null);
  const [imagePath, setImagePath] = useState("");
  const [image, setImage] = useState("");
  const [fileFormat, setFileFormat] = useState("");
  const [thumbnail, setThumbnail] = useState("");

  //day
  const [monday, setMonday] = useState(false);
  const [Tuesday, setTuesday] = useState(false);
  const [Wednesday, setWednesday] = useState(false);
  const [Thursday, setThursday] = useState(false);
  const [Friday, setFriday] = useState(false);
  const [Saturday, setSaturday] = useState(false);
  const [Sunday, setSunday] = useState(false);
  const [operationalDay, setOperationalDay] = useState([
    {
      openDay: 0,
      isOpen: false,
    },
    {
      openDay: 1,
      isOpen: false,
    },
    {
      openDay: 2,
      isOpen: false,
    },
    {
      openDay: 3,
      isOpen: false,
    },
    {
      openDay: 4,
      isOpen: false,
    },
    {
      openDay: 5,
      isOpen: false,
    },
    {
      openDay: 6,
      isOpen: false,
    },
  ]);

  //alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const onCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onNumberOfUsageChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNumberOfUsage(parseInt(e.target.value));
  };

  useEffect(() => {
    if (voucherIdState !== "") {
      dispatch(
        createAvailableDayData({
          discountId: voucherIdState,
          operationalDay,
        } as AvailableDayModel)
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucherIdState]);

  useEffect(() => {
    if (createStatus === "success") {
      setAlertProps({
        variant: "success",
        title: "Berhasil",
        content: " ",
      });
      setShowAlert(true);
    } else if (createStatus === "failed") {
      let arr = JSON.stringify(errorMessage);
      let parse = JSON.parse(arr);
      let message = parse.data.message.toString();
      setAlertProps({
        variant: "failed",
        title: "Gagal",
        content: message,
      });
      setShowAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus]);

  useEffect(() => {
    if (listAvailableDayState.length !== 0) {
      var temp = operationalDay;
      operationalDay.map((item) => {
        const found = listAvailableDayState.find(
          (element) => element.openDay === item.openDay
        );
        if (found !== undefined) {
          temp[found.openDay].isOpen =
            listAvailableDayState[found.openDay].isOpen;
          switch (found.openDay) {
            case 0:
              return setMonday(found.isOpen);
            case 1:
              return setTuesday(found.isOpen);
            case 2:
              return setWednesday(found.isOpen);
            case 3:
              return setThursday(found.isOpen);
            case 4:
              return setFriday(found.isOpen);
            case 5:
              return setSaturday(found.isOpen);
            case 6:
              return setSunday(found.isOpen);
          }
        }
      });
      setOperationalDay(temp);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listAvailableDayState]);

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
      console.log("ada DATA");
      setCode(data.code);
      setName(data.name);
      setType(data.type);
      setStartDate(new Date(data.startDate));
      setEndDate(new Date(data.endDate));
      setNumberOfUsage(data.numberOfUsage);
      setAppliesMultiple(data.appliesMultiple);
      setIsMembership(data.isMembership);

      setDiscountValue(data.discountValue);
      setMaximumAmount(data.maximumAmount);
      setValueType(data.valueType);
      setIsEntireOrder(data.isEntireOrder);
      setIsSpecificCollection(data.isSpecificCollection);
      setCollectionListId(data.collectionListId);
      setProductListId(data.productListId);
      setIsNoneMinimumRequirement(data.isNoneMinimumRequirement);
      setIsMinimumPurchaseAmount(data.isMinimumPurchaseAmount);

      setIsNumberOfUsage(data.isNumberOfUsage);
      setIsLimitUsePerCustomer(data.isLimitUsePerCustomer);
      setIsAllCity(data.isAllCity);
      setIsSelectedCity(data.isSelectedCity);
      setSelectedCity(data.selectedCity);
      setIsExcludeShipping(data.isExcludeShipping);
      setExcludeShipping(data.excludeShipping);
      setCustomerGetAmountQty(data.customerGetAmountQty);
      setAnyItemFrom(data.anyItemFrom);
      setIsFree(data.isFree);
      setIsNominalCashback(data.isNominalCashback);
      // setNominalCashback(data.nominalCashback);
      setIsMaximumAmountPercentage(data.isMaximumAmountPercentage);
      // setMaximumAmountPercentage(data.maximumAmountPercentage);
      setIsUpto(data.isUpto);
      setUpto(data.upto);
      setQuota(data.quota);
      setMaximumEstimatedStoreExpenses(data.maximumEstimatedStoreExpenses);
      setNumberOfUsage(data.numberOfUsage);
      setIsSpecificProduct(data.isSpecificProduct);
      setDiscountMinimumRequirementType(data.discountMinimumRequirementType);

      if (data.discountMinimumRequirementType === 2) {
        setMinimumPurchaseAmount(0);
        setMinimumQuantityOfItems(data.minimumPurchaseAmount);
      } else if (data.discountMinimumRequirementType === 1) {
        setMinimumPurchaseAmount(data.minimumPurchaseAmount);
        setMinimumQuantityOfItems(0);
      } else {
        setMinimumPurchaseAmount(0);
        setMinimumQuantityOfItems(0);
      }

      setNominalCashbackType(data.nominalCashbackType)

      if(data.nominalCashbackType === 1){
        setDiscountValue(0)
        setDiscountCashbackAmountPercentage(data.discountValue)
      }else if (data.nominalCashbackType === 0){
        setDiscountValue(data.discountValue)
        setDiscountCashbackAmountPercentage(0)
      }

      if(data.productId!=="" && data.productId!==null){
        setProductId(data.productId)
        dispatch(getListVariationByProduct(data.productId))
      }
      console.log(data.isHidden);

      setImagePath(data.image);
      setIsActive(data.isActive);
      setIsHidden(data.isHidden);
      setIsExpired(data.isExpired);

      if (data.productListId !== "" && data.productListId !== null) {
        setProductCode(data.productListId.split(","));
      }

      if (data.freeItemId !== "" && data.freeItemId !== null) {
       
        if(data.type===3){
          setFreeItemId(data.freeItemId)
        } else {
          setProductFreeCode(data.freeItemId.split(","));
        }
      }
    } else {
      
      setCode("");
      setName("");
      setStartDate(new Date());
      setEndDate(new Date());
      setAppliesMultiple(false);
      setIsMembership(false);
      setDiscountValue(0);
      setMaximumAmount(0);
      setValueType(0);
      setIsEntireOrder(true);
      setIsSpecificCollection(false);
      setCollectionListId("");
      setProductListId("");
      setIsNoneMinimumRequirement(true);
      setIsMinimumPurchaseAmount(true);
      setMinimumPurchaseAmount(0);
      setIsMinimumQuantityOfItems(true);
      setIsNumberOfUsage(false);
      setIsLimitUsePerCustomer(false);
      setIsAllCity(true);
      setIsSelectedCity(true);
      setSelectedCity("");
      setIsExcludeShipping(false);
      setExcludeShipping(0);
      setCustomerGetAmountQty(0);
      setAnyItemFrom(0);
      setIsFree(true);
      setIsNominalCashback(true);
      setNominalCashback(0);
      setIsMaximumAmountPercentage(true);
      setMaximumAmountPercentage(0);
      setIsUpto(true);
      setUpto(0);
      setQuota(0);
      setMaximumEstimatedStoreExpenses(0);
      setNumberOfUsage(0);
      setIsSpecificProduct(true);
      setDiscountMinimumRequirementType(0);
      // setImagePath("");
      setIsActive(true);
      setIsHidden(false);
      setIsExpired(data.isExpired);
      // setProductCode(null);
      // setFreeItemId("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onAlertClose = () => {
    setShowAlert(false);
    if (createStatus === "success") {
      dispatch(resetStatus());
      history.replace("/Voucher/index");
    } else {
      dispatch(resetStatus());
    }
  };

  const onStartDateChange = (value: any, dateString: any) => {
    if (value !== null) {
      var date = value._d;
      date.setHours(date.getHours() + 7);
      setStartDate(date);
    }
  };

  const onEndDateChange = (value: any, dateString: any) => {
    if (value !== null) {
      var date = value._d;
      date.setHours(date.getHours() + 7);
      setEndDate(date);
    }
  };

  const onProductListChange = (e: any) => {
    setProductCode(e);
    setProductListId(e.toString());
    // console.log(e.toString())
  };

  const onProductFreeListChange = (e: any) => {

    if(type===3){
      setProductId(e.toString())
      dispatch(getListVariationByProduct(e.toString()))
    } else {
      setFreeItemId(e.toString());
      setProductFreeCode(e);
    }

  };

  const onVariantFreeListChange = (e: any) => {
    setFreeItemId(e.toString());
  };

  const onSwitchMultipleChange = () => {
    setAppliesMultiple(!appliesMultiple);
  };

  const onSwitchMembershipChange = () => {
    setIsMembership(!isMembership);
  };

  const onSwitchAvailableDayChange = (day: number) => {
    var temp = operationalDay;
    temp[day].isOpen = !temp[day].isOpen;

    setOperationalDay(temp);

    switch (day) {
      case 0:
        return setMonday(temp[day].isOpen);
      case 1:
        return setTuesday(temp[day].isOpen);
      case 2:
        return setWednesday(temp[day].isOpen);
      case 3:
        return setThursday(temp[day].isOpen);
      case 4:
        return setFriday(temp[day].isOpen);
      case 5:
        return setSaturday(temp[day].isOpen);
      case 6:
        return setSunday(temp[day].isOpen);
    }
  };

  const handleImage = (files: any) => {
    setFileFormat("");
    setThumbnail("");
    
    if (files[0] !== undefined) {
      setFileFormat(files[0].type.split("/")[1]);
      convertBase64(files[0], (result: any) => {
        setImagePath(result);
        setImage(result)
      });
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    if (imagePath != null && imagePath != "") {

      let photoName = code !==  "" ? code : moment(new Date()).format("yymmdd-hhmm") 

      dispatch(
        uploadPhoto({
          file_name: photoName + "." + fileFormat,
          base64url: imagePath.split(",")[1],
          imagepath: "voucherPhoto",
          location: "voucherPhoto",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagePath, fileFormat]);

  return (
    <div>
      <Grid textAlign="center" style={{ height: "100vh" }}>
        <Card>
          <Card.Body>
            <Form
                id="voucherForm"
                noValidate
                validated={validated}
                onSubmit={(e) => {
                  const form = e.currentTarget;
                  if (form.checkValidity() === false) {
                    e.preventDefault();
                    e.stopPropagation();
                  } else {
                    e.preventDefault();

                    console.log(discountMinimumRequirementType);
                    props.onSubmit(
                        id,
                        name,
                        code,
                        type,
                        appliesMultiple,
                        isMembership,
                        roleIdList,
                        membershipTierList,
                        isEnableStore,
                        startDate,
                        endDate,

                        // discountValue,
                        type === 4 && nominalCashbackType === 1 ? discountCashbackAmountPercentage : discountValue,

                        maximumAmount,
                        isEntireOrder,
                        isSpecificCollection,
                        collectionListId,
                        isSpecificProduct,
                        productListId,
                        isNoneMinimumRequirement,
                        isMinimumPurchaseAmount,

                        // minimumPurchaseAmount,
                        discountMinimumRequirementType === 2
                            ? minimumQuantityOfItems
                            : minimumPurchaseAmount,

                        isMinimumQuantityOfItems,
                        isNumberOfUsage,
                        numberOfUsage,
                        isLimitUsePerCustomer,
                        isAllCity,
                        isSelectedCity,
                        selectedCity,
                        isExcludeShipping,
                        excludeShipping,
                        customerGetAmountQty,
                        anyItemFrom,
                        isFree,
                        isNominalCashback,
                        nominalCashback,
                        isMaximumAmountPercentage,
                        maximumAmountPercentage,
                        isUpto,
                        upto,
                        quota,
                        maximumEstimatedStoreExpenses,
                        // valueType,
                        discountMinimumRequirementType,
                        imageState.toString(),
                        isActive,
                        isExpired,
                        freeItemId === "" ? null : freeItemId,
                        nominalCashbackType,
                        minimumQuantityOfItems,
                        valueType,
                        type !== 3 ? null : productId === "" ? null : productId,
                        isHidden
                    );
                  }
                  setValidated(true);
                }}
            >
              <section>
                <h6 className="input-padding">Discount Code</h6>
                <hr/>
                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    Discount Code
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                        onChange={onCodeChange}
                        value={code}
                        type="text"
                        required
                    ></Form.Control>
                  </Col>
                </Form.Group>
                <hr/>
                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    Discount Name
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                        type="text"
                        onChange={onNameChange}
                        value={name}
                        required
                    />
                  </Col>
                </Form.Group>
              </section>
              <section>
                <hr/>
                <h6 style={{paddingTop: 15, paddingBottom: 15}}>Type</h6>
                <hr/>
                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    Type
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Check
                        label="Percentage"
                        name="type"
                        type="radio"
                        style={{paddingBottom: 10}}
                        checked={type === 0}
                        value={0}
                        onChange={() => setType(0)}
                    />

                    <Form.Check
                        label="Fixed Amount"
                        name="type"
                        type="radio"
                        style={{paddingBottom: 10}}
                        checked={type === 1}
                        value={1}
                        onChange={() => setType(1)}
                    />

                    <Form.Check
                        label="Free Shipping"
                        name="type"
                        type="radio"
                        style={{paddingBottom: 10}}
                        checked={type === 2}
                        value={2}
                        onChange={() => setType(2)}
                    />

                    <Form.Check
                        label="Buy X get Y"
                        name="type"
                        type="radio"
                        style={{paddingBottom: 10}}
                        checked={type === 3}
                        value={3}
                        onChange={() => setType(3)}
                    />

                    <Form.Check
                        label="Cash Back"
                        name="type"
                        type="radio"
                        style={{paddingBottom: 10}}
                        checked={type === 4}
                        value={4}
                        onChange={() => setType(4)}
                    />
                  </Col>
                </Form.Group>
              </section>
              <section>
                <hr/>
                <h6 style={{paddingTop: 15, paddingBottom: 15}}>Value</h6>
                <hr/>
                {type === 0 && (
                    <>
                      <section>
                        <Form.Group as={Row} className="input-padding">
                          <Form.Label column sm={4}>
                            Discount Value <b> {"(%)"}</b>
                          </Form.Label>
                          <Col sm={8}>
                            <Form.Control
                                value={discountValue}
                                onChange={(e) =>
                                    setDiscountValue(parseInt(e.target.value))
                                }
                                type="number"
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                        <hr/>
                        <Form.Group as={Row} className="input-padding">
                          <Form.Label column sm={4}>
                            Maximum Amount
                          </Form.Label>
                          <Col sm={8}>
                            <NumberFormat
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                className="form-control"
                                inputMode="numeric"
                                prefix="Rp. "
                                value={maximumAmount}
                                onValueChange={({floatValue}) => {
                                  let val = floatValue ?? 0;
                                  setMaximumAmount(parseInt(val.toString()));
                                }}
                            />
                            {/* <Form.Control
                            type="text"
                            value={maximumAmount}
                            onChange={(e) =>
                              setMaximumAmount(parseInt(e.target.value))
                            }
                          /> */}
                          </Col>
                        </Form.Group>
                      </section>
                      <section>
                        <hr/>
                        <Form.Group as={Row} className="input-padding">
                          <Form.Label column sm={4}>
                            {" "}
                          </Form.Label>
                          <Col sm={8}>
                            <Form.Check
                                label="Entire Order"
                                type="radio"
                                name="productDiscount"
                                style={{paddingBottom: 10}}
                                checked={valueType === 0}
                                value={0}
                                onChange={() => setValueType(0)}
                            />

                            {/* <Form.Check
                            label="Specification Collections"
                            type="radio"
                            style={{ paddingBottom: 10 }}
                            checked={valueType === 1}
                            value={1}
                            onChange={() => setValueType(1)}
                          /> */}

                            {/* <Form.Group as={Row} className="input-padding">
                            <Col sm={12}>
                              <Form.Control
                                as="select"
                                value={collectionListId}
                                onChange={(e) =>
                                  setCollectionListId(e.target.value)
                                }
                              >
                                <option>select</option>
                                {collectionTagState.map((item) => {
                                  return (
                                    <option value={item.id}>{item.name}</option>
                                  );
                                })}
                              </Form.Control>
                            </Col>
                          </Form.Group> */}

                            <Form.Check
                                label="Specific Product"
                                type="radio"
                                name="productDiscount"
                                checked={valueType === 2}
                                value={2}
                                onChange={() => setValueType(2)}
                            />

                            <Form.Group as={Row} className="input-padding">
                              <Col sm={12}>
                                <Select
                                    // mode="multiple"
                                    value={productCode}
                                    onChange={onProductListChange}
                                    style={{width: "100%"}}
                                >
                                  {productState.map((item) => (
                                      <Select.Option
                                          key={item.name}
                                          value={item.id}
                                      >
                                        {item.inventorySKU} - {item.name}
                                      </Select.Option>
                                  ))}
                                </Select>
                              </Col>
                            </Form.Group>
                          </Col>
                        </Form.Group>
                      </section>
                    </>
                )}
                {type === 1 && (
                    <>
                      <section>
                        <Form.Group as={Row} className="input-padding">
                          <Form.Label column sm={4}>
                            Discount Value <b> {"(Rp.)"}</b>
                          </Form.Label>
                          <Col sm={8}>
                            <NumberFormat
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                className="form-control"
                                inputMode="numeric"
                                prefix="Rp. "
                                value={discountValue}
                                onValueChange={({floatValue}) => {
                                  let val = floatValue ?? 0;
                                  setDiscountValue(parseInt(val.toString()));
                                }}
                            />
                            {/* <Form.Control
                            onChange={(e) =>
                              setDiscountValue(parseInt(e.target.value))
                            }
                            value={discountValue}
                            type="text"
                            required
                          ></Form.Control> */}
                          </Col>
                        </Form.Group>
                      </section>
                      <section>
                        <hr/>
                        <Form.Group as={Row} className="input-padding">
                          <Form.Label column sm={4}>
                            {" "}
                          </Form.Label>
                          <Col sm={8}>
                            <Form.Check
                                label="Entire Order"
                                type="radio"
                                style={{paddingBottom: 10}}
                                checked={valueType === 0}
                                value={0}
                                onChange={() => setValueType(0)}
                            />

                            {/* <Form.Check
                            label="Specification Collections"
                            type="radio"
                            style={{ paddingBottom: 10 }}
                          />

                          <Form.Group as={Row} className="input-padding">
                            <Col sm={12}>
                              <Form.Control as="select">
                                <option>Best Seller</option>
                              </Form.Control>
                            </Col>
                          </Form.Group> */}

                            <Form.Check
                                label="Specific Product"
                                type="radio"
                                style={{paddingBottom: 10}}
                                checked={valueType === 3}
                                value={3}
                                onChange={() => setValueType(3)}
                            />

                            <Form.Group as={Row} className="input-padding">
                              <Col sm={12}>
                                <Select
                                    placeholder="Select Productr"
                                    value={productCode}
                                    onChange={onProductListChange}
                                    style={{width: "100%"}}
                                >
                                  {productState.map((item) => (
                                      <Select.Option
                                          key={item.name}
                                          value={item.id}
                                      >
                                        {item.name}
                                      </Select.Option>
                                  ))}
                                </Select>
                              </Col>
                            </Form.Group>
                          </Col>
                        </Form.Group>
                      </section>
                    </>
                )}
                {type === 2 && (
                    <>
                      <section>
                        <Form.Group as={Row} className="input-padding">
                          <Form.Label column sm={4}>
                            City
                          </Form.Label>
                          <Col sm={8}>
                            <Form.Check
                                label="All City"
                                type="radio"
                                checked={isAllCity === true}
                                onClick={() => setIsAllCity(true)}
                                style={{paddingBottom: 10}}
                            />

                            <Form.Check
                                label="Selected City"
                                type="radio"
                                checked={isAllCity === false}
                                onClick={() => setIsAllCity(false)}
                                style={{paddingBottom: 10}}
                            />

                            <Form.Group as={Row} className="input-padding">
                              <Col sm={12}>
                                <Form.Control
                                    as="select"
                                    value={selectedCity}
                                    onChange={(e) =>
                                        setSelectedCity(e.target.value)
                                    }
                                >
                                  <option>select</option>
                                  {cityState.map((item) => {
                                    return (
                                        <option value={item.id}>{item.name}</option>
                                    );
                                  })}
                                </Form.Control>
                              </Col>
                            </Form.Group>

                            <Form.Check
                                label="Exclude shipping rates over a certain amount"
                                name="excludeShipping"
                                type="checkbox"
                                checked={isExcludeShipping}
                                onChange={(e) => {
                                  setIsExcludeShipping(e.target.checked);
                                }}
                            />

                            <Form.Group as={Row} className="input-padding">
                              <Col sm={12}>
                                <Form.Control
                                    value={excludeShipping}
                                    onChange={(e) => {
                                      setExcludeShipping(parseInt(e.target.value));
                                    }}
                                    type="number"
                                ></Form.Control>
                              </Col>
                            </Form.Group>
                          </Col>
                        </Form.Group>
                      </section>
                    </>
                )}
                {type === 3 && (
                    <>
                      <section>
                        <Form.Group as={Row} className="input-padding">
                          <Form.Label column sm={4}>
                            Customer Buys
                          </Form.Label>
                          <Col sm={8}>
                            <Form.Check
                                label="Minimum Quantity of Items"
                                type="radio"
                                name="customerBuy"
                                style={{paddingBottom: 10}}
                                checked={discountMinimumRequirementType === 2}
                                value={2}
                                onClick={() => {
                                  setDiscountMinimumRequirementType(2);
                                }}
                            />
                            <Form.Control
                                type="number"
                                value={minimumQuantityOfItems}
                                onChange={(e) =>
                                    setMinimumQuantityOfItems(
                                        parseInt(e.target.value)
                                    )
                                }
                            ></Form.Control>

                            <Form.Check
                                label="Minimum Purchase Amount"
                                type="radio"
                                name="customerBuy"
                                style={{paddingBottom: 10}}
                                checked={discountMinimumRequirementType === 1}
                                value={1}
                                onClick={() => {
                                  setDiscountMinimumRequirementType(1);
                                }}
                            />
                            <Form.Control
                                value={minimumPurchaseAmount}
                                onChange={(e) =>
                                    setMinimumPurchaseAmount(parseInt(e.target.value))
                                }
                                type="number"
                                required
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                      </section>
                      <section>
                        <hr/>
                        <h6>Customer Gets</h6>
                        <Form.Label className="card-subtitle">
                          Customer must add quantity of items specified below to
                          their cart
                        </Form.Label>
                        <hr/>
                        {/* <Form.Group as={Row} className="input-padding">
                        <Form.Label column sm={4}>
                          Amount Qty
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                            value={customerGetAmountQty}
                            onChange={(e) =>
                              setCustomerGetAmountQty(parseInt(e.target.value))
                            }
                            type="number"
                            required
                          ></Form.Control>
                        </Col>
                      </Form.Group> */}

                        <Form.Group as={Row} className="input-padding">
                          <Form.Label column sm={4}>
                            Any Item From
                          </Form.Label>
                          <Col sm={8}>
                            <Form.Control as="select">
                              {/* <option value={0}>Specific Collections</option> */}
                              <option value={1}>Specific Products</option>
                            </Form.Control>

                            <Row className="mt-2">
                              <Col sm={12}>
                                <Select

                                    placeholder="Select Product"
                                    value={productId}
                                    onChange={onProductFreeListChange}
                                    style={{width: "100%"}}
                                    showSearch
                                    optionLabelProp="label"
                                    optionFilterProp="children"
                                    // filterOption={(input, option) =>
                                    //   option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    // }
                                >
                                  {productState.map((item) => (
                                      <Select.Option
                                          key={item.name}
                                          value={item.id}
                                          label={item.name}
                                      >
                                        {item.name}
                                      </Select.Option>
                                  ))}
                                </Select>
                              </Col>
                            </Row>

                            <Row className="mt-2">
                              <Col sm={12}>
                                <Select
                                    placeholder="Select Variant"
                                    value={freeItemId}
                                    onChange={onVariantFreeListChange}
                                    style={{width: "100%"}}
                                    showSearch
                                    optionLabelProp="label"
                                    optionFilterProp="children"
                                >
                                  {variantState.map((item) => (
                                      <Select.Option
                                          key={item.name}
                                          value={item.id}
                                          label={item.name}
                                      >
                                        {item.name}
                                      </Select.Option>
                                  ))}
                                </Select>
                              </Col>
                            </Row>

                            {/* <Form.Group as={Row} className="input-padding">
                            <Col sm={12}>
                              <InputGroup
                                className="mb-3"
                                style={{ width: "100%" }}
                              >
                                <InputGroup.Prepend className="search-input">
                                  <InputGroup.Text
                                    style={{
                                      backgroundColor: "#fff",
                                      color: "#F5B95D",
                                      border: 0,
                                    }}
                                  >
                                    <FaSearch />
                                  </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                  style={{ borderLeft: 0 }}
                                  placeholder="Search"
                                />
                              </InputGroup>
                            </Col>
                          </Form.Group> */}
                          </Col>
                        </Form.Group>
                      </section>
                      <section>
                        <hr/>
                        <h6>At discount Value</h6>
                        <hr/>
                        {" "}
                        <Form.Group as={Row} className="input-padding">
                          <Form.Label column sm={4}>
                            At discount Value
                          </Form.Label>
                          <Col sm={8}>
                            {/* <Form.Check
                            label="Percentage"
                            type="radio"
                            name="atDiscountValue"
                            style={{ paddingBottom: 10 }}
                            checked={isMaximumAmountPercentage === true}
                            onClick={() => {
                              setIsMaximumAmountPercentage(true);
                              setIsFree(false);
                            }}
                          /> */}

                            <Form.Check
                                label="Free"
                                type="checkbox"
                                style={{paddingBottom: 10}}
                                checked={isFree}
                                onChange={(e) => {
                                  // setIsMaximumAmountPercentage(false);
                                  setIsFree(e.target.checked);
                                }}
                            />

                            {isFree ? (
                                <>
                                  <Form.Group as={Row} className="input-padding">
                                    <Form.Label column sm={4}>
                                      Discount Value
                                    </Form.Label>
                                    <Col sm={12}>
                                      <Form.Control
                                          value={discountValue}
                                          // onChange={(e) =>
                                          //   setDiscountValue(parseInt(e.target.value))
                                          // }
                                          type="number"
                                          disabled
                                      ></Form.Control>
                                    </Col>
                                  </Form.Group>

                                  <Form.Group as={Row} className="input-padding">
                                    <Form.Label column sm={4}>
                                      Max Amount
                                    </Form.Label>
                                    <Col sm={12}>
                                      <Form.Control
                                          value={maximumAmount}
                                          // onChange={(e) =>
                                          //   setMaximumAmountPercentage(
                                          //     parseInt(e.target.value)
                                          //   )
                                          // }
                                          type="number"
                                          disabled
                                      ></Form.Control>
                                    </Col>
                                  </Form.Group>
                                </>
                            ) : (
                                <>
                                  <Form.Group as={Row} className="input-padding">
                                    <Form.Label column sm={4}>
                                      Discount Value
                                    </Form.Label>
                                    <Col sm={12}>
                                      <Form.Control
                                          value={discountValue}
                                          onChange={(e) =>
                                              setDiscountValue(parseInt(e.target.value))
                                          }
                                          type="number"
                                      ></Form.Control>
                                    </Col>
                                  </Form.Group>

                                  <Form.Group as={Row} className="input-padding">
                                    <Form.Label column sm={4}>
                                      Max Amount
                                    </Form.Label>
                                    <Col sm={12}>
                                      <Form.Control
                                          value={maximumAmount}
                                          onChange={(e) =>
                                              setMaximumAmount(
                                                  parseInt(e.target.value)
                                              )
                                          }
                                          type="number"
                                      ></Form.Control>
                                    </Col>
                                  </Form.Group>
                                </>
                            )}
                          </Col>
                        </Form.Group>
                      </section>
                    </>
                )}
                {type === 4 && (
                    <section>
                      <Form.Group as={Row} className="input-padding">
                        <Form.Label column sm={4}>
                          Nominal Cashback
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Check
                              label="IDR"
                              type="radio"
                              name="nominalCashBack"
                              style={{paddingBottom: 10}}
                              checked={nominalCashbackType === 0}
                              value={0}
                              onClick={() => {
                                setNominalCashbackType(0);
                                // console.log(discountMinimumRequirementType);
                              }}
                          />
                          <NumberFormat
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              className="form-control"
                              inputMode="numeric"
                              prefix="Rp. "
                              value={discountValue}
                              onValueChange={({floatValue}) => {
                                let val = floatValue ?? 0;
                                setDiscountValue(parseInt(val.toString()));
                              }}
                          />
                          {/* <Form.Control
                          value={nominalCashback}
                          onChange={(e) =>
                            setNominalCashback(parseInt(e.target.value))
                          }
                          type="number"
                          required
                        ></Form.Control> */}
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="input-padding">
                        <Form.Label column sm={4}></Form.Label>
                        <Col sm={8}>
                          <Form.Check
                              label="Percentage"
                              type="radio"
                              name="nominalCashBack"
                              style={{paddingBottom: 10}}
                              checked={nominalCashbackType === 1}
                              value={1}
                              onClick={() => {
                                setNominalCashbackType(1);
                                // console.log(discountMinimumRequirementType);
                              }}
                          />
                          <Form.Control
                              style={{paddingBottom: 10}}
                              type="number"
                              value={discountCashbackAmountPercentage}
                              onChange={(e) =>
                                  setDiscountCashbackAmountPercentage(parseInt(e.target.value))
                              }
                          ></Form.Control>

                          <Form.Label style={{paddingTop: 20}}>
                            Up to
                          </Form.Label>
                          <Form.Control
                              value={maximumAmount}
                              onChange={(e) => setMaximumAmount(parseInt(e.target.value))}
                              type="number"
                              required
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                    </section>
                )}
              </section>

              {type !== 3 && (
                  <section>
                    <hr/>
                    <h6 style={{paddingTop: 15, paddingBottom: 15}}>
                      Minimum Requirements
                    </h6>
                    <hr/>
                    <Form.Group as={Row} className="input-padding">
                      <Form.Label column sm={4}>
                        Minimum Requirement
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Check
                            label="None"
                            name="group1"
                            type="radio"
                            style={{paddingBottom: 10}}
                            checked={discountMinimumRequirementType === 0}
                            value={0}
                            onClick={() => {
                              setDiscountMinimumRequirementType(0);
                              setIsNoneMinimumRequirement(true);
                              setIsMinimumPurchaseAmount(false);
                              setIsMinimumQuantityOfItems(false);
                              // console.log(discountMinimumRequirementType);
                            }}
                        />
                        <Form.Check
                            label="Minimum Purchase Amount(IDR)"
                            name="group1"
                            type="radio"
                            style={{paddingBottom: 10}}
                            checked={discountMinimumRequirementType === 1}
                            value={1}
                            onClick={() => {
                              setDiscountMinimumRequirementType(1);
                              // setIsNoneMinimumRequirement(false)
                              // setIsMinimumPurchaseAmount(true)
                              // setIsMinimumQuantityOfItems(false)
                              // console.log(discountMinimumRequirementType);
                            }}
                        />

                        <NumberFormat
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            className="form-control"
                            inputMode="numeric"
                            prefix="Rp. "
                            value={minimumPurchaseAmount}
                            onValueChange={({floatValue}) => {
                              let val = floatValue ?? 0;
                              setMinimumPurchaseAmount(parseInt(val.toString()));
                            }}
                        />
                        {/* <Form.Control
       value={minimumPurchaseAmount}
       onChange={(e) =>
         setMinimumPurchaseAmount(parseInt(e.target.value))
       }
       type="text"
     /> */}
                        <Form.Check
                            label="Minimum Quantity Items"
                            name="group1"
                            type="radio"
                            style={{paddingBottom: 10}}
                            checked={discountMinimumRequirementType === 2}
                            value={2}
                            onClick={() => {
                              setDiscountMinimumRequirementType(2);
                              // setIsNoneMinimumRequirement(false)
                              // setIsMinimumPurchaseAmount(false)
                              // setIsMinimumQuantityOfItems(true)
                              // console.log(discountMinimumRequirementType);
                            }}
                        />
                        <Form.Control
                            type="number"
                            value={minimumQuantityOfItems}
                            onChange={(e) => {
                              setMinimumQuantityOfItems(parseInt(e.target.value));
                            }}
                        />
                      </Col>
                    </Form.Group>
                  </section>
              )}

              <section>
                <hr/>
                <h6 style={{paddingTop: 15, paddingBottom: 15}}>
                  Usage Limits
                </h6>
                <hr/>
                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    Usage Limits
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Check
                        style={{paddingBottom: 15}}
                        label="Limit number of times this discount can be used in total"
                        name="limitNumber"
                        type="checkbox"
                        checked={isNumberOfUsage}
                        onChange={(e) => {
                          setIsNumberOfUsage(e.target.checked);
                        }}
                    />
                    <Form.Control
                        type="number"
                        value={numberOfUsage}
                        onChange={onNumberOfUsageChange}
                        required
                    />
                    <Form.Check
                        style={{paddingTop: 15}}
                        label="Limit to one use per customer"
                        name="limitNumber"
                        type="checkbox"
                        checked={isLimitUsePerCustomer}
                        onChange={(e) => {
                          setIsLimitUsePerCustomer(e.target.checked);
                        }}
                    />
                  </Col>
                </Form.Group>
              </section>

              <section>
                <hr/>
                <h6 style={{paddingTop: 15, paddingBottom: 15}}>
                  Active Dates
                </h6>
                <hr/>
                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    Start Date
                  </Form.Label>
                  <Col sm={8}>
                    <DatePicker
                        defaultValue={moment(startDate, "YYYY-MM-DD HH:mm")}
                        value={moment(startDate, "YYYY-MM-DD HH:mm")}
                        style={{width: "100%"}}
                        showTime={{format: "HH:mm"}}
                        format="YYYY-MM-DD HH:mm"
                        onChange={onStartDateChange}
                    />
                  </Col>
                </Form.Group>
                <hr/>
                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    End Date
                  </Form.Label>
                  <Col sm={8}>
                    <DatePicker
                        defaultValue={moment(endDate, "YYYY-MM-DD HH:mm")}
                        value={moment(endDate, "YYYY-MM-DD HH:mm")}
                        style={{width: "100%"}}
                        showTime={{format: "HH:mm"}}
                        format="YYYY-MM-DD HH:mm"
                        onChange={onEndDateChange}
                    />
                  </Col>
                </Form.Group>
              </section>

              {/* <section>
                <hr />
                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    Expired
                  </Form.Label>
                  <Col sm={4}>
                    <DragSwitch
                      checked={isExpired}
                      onChange={(e) => {
                        setIsExpired(e);
                      }}
                    />
                    <br />
                  </Col>
                </Form.Group>
              </section> */}

              <section>
                <hr/>
                <h6 style={{paddingTop: 15, paddingBottom: 15}}>
                  Available Days
                </h6>
                <hr/>
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    Monday
                  </Form.Label>
                  <Col sm={8}>
                    <Switch
                        onChange={(e) => {
                          onSwitchAvailableDayChange(0);
                        }}
                        checkedChildren="On"
                        unCheckedChildren="Off"
                        checked={operationalDay[0].isOpen}
                    />
                  </Col>
                </Form.Group>
                <hr/>
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    Tuesday
                  </Form.Label>
                  <Col sm={8}>
                    <Switch
                        onChange={(e) => {
                          onSwitchAvailableDayChange(1);
                        }}
                        checkedChildren="On"
                        unCheckedChildren="Off"
                        checked={operationalDay[1].isOpen}
                    />
                  </Col>
                </Form.Group>
                <hr/>
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    Wednesday
                  </Form.Label>
                  <Col sm={8}>
                    <Switch
                        onChange={(e) => {
                          onSwitchAvailableDayChange(2);
                        }}
                        checkedChildren="On"
                        unCheckedChildren="Off"
                        checked={operationalDay[2].isOpen}
                    />
                  </Col>
                </Form.Group>
                <hr/>
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    Thursday
                  </Form.Label>
                  <Col sm={8}>
                    <Switch
                        onChange={(e) => {
                          onSwitchAvailableDayChange(3);
                        }}
                        checkedChildren="On"
                        unCheckedChildren="Off"
                        checked={operationalDay[3].isOpen}
                    />
                  </Col>
                </Form.Group>
                <hr/>
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    Friday
                  </Form.Label>
                  <Col sm={8}>
                    <Switch
                        onChange={(e) => {
                          onSwitchAvailableDayChange(4);
                        }}
                        checkedChildren="On"
                        unCheckedChildren="Off"
                        checked={operationalDay[4].isOpen}
                    />
                  </Col>
                </Form.Group>
                <hr/>
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    Saturday
                  </Form.Label>
                  <Col sm={8}>
                    <Switch
                        onChange={(e) => {
                          onSwitchAvailableDayChange(5);
                        }}
                        checkedChildren="On"
                        unCheckedChildren="Off"
                        checked={operationalDay[5].isOpen}
                    />
                  </Col>
                </Form.Group>
                <hr/>
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    Sunday
                  </Form.Label>
                  <Col sm={8}>
                    <Switch
                        onChange={(e) => {
                          onSwitchAvailableDayChange(6);
                        }}
                        checkedChildren="On"
                        unCheckedChildren="Off"
                        checked={operationalDay[6].isOpen}
                    />
                  </Col>
                </Form.Group>
              </section>
              <section>
                {/* <hr />
                <h6 style={{ paddingTop: 15, paddingBottom: 15 }}>
                  Multiple Discounts
                </h6>
                <hr />
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    Customers will be able to enter a code if an automatic
                    discount is already applied at checkout
                  </Form.Label>
                  <Col sm={8}>
                    <br />

                    <Switch
                      checked={appliesMultiple}
                      checkedChildren="On"
                      unCheckedChildren="Off"
                      onChange={onSwitchMultipleChange}
                    />
                  </Col>
                </Form.Group> */}
                {/* <hr />
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    Membership
                  </Form.Label>
                  <Col sm={8}>
                    <Switch
                      checkedChildren="On"
                      unCheckedChildren="Off"
                      onChange={onSwitchMembershipChange}
                      checked={isMembership}
                    />
                  </Col>
                </Form.Group> */}
              </section>

              <section>
                <hr/>
                <h6 style={{paddingTop: 15, paddingBottom: 15}}>Image</h6>
                <hr/>
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    Image
                  </Form.Label>
                  <Col sm={8}>
                    <Dropzone
                        onDrop={(acceptedFiles) => handleImage(acceptedFiles)}
                    >
                      {({getRootProps, getInputProps}) => (
                          <div className="dropzone file-upload-frame w-100">
                            <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="text-center file-upload-label">
                                <div>
                                  {/* {console.log(imageKtp)} */}

                                  {imagePath !== "" && (
                                      <img
                                          src={
                                            isEdit
                                                ? image !== "" ? image : endpoint + data.image
                                                : image
                                          }
                                          className="file-upload-image mb3"
                                      />
                                  )}

                                  {imagePath === "" &&
                                      Object.keys(data).length === 0 && (
                                          <>
                                            <div
                                                className="file-upload-image mb3"
                                                style={{paddingTop: 50}}
                                            >
                                              <img src={upload} alt="upload"/>
                                              <br/>
                                              <span style={{color: "black"}}>
                                          Select File
                                        </span>
                                            </div>
                                          </>
                                      )}
                                </div>
                              </div>
                            </div>
                          </div>
                      )}
                    </Dropzone>
                  </Col>
                </Form.Group>
              </section>

              <section>
                <hr/>
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    Active
                  </Form.Label>
                  <Col sm={8}>
                    <Switch
                        onChange={(e) => {
                          setIsActive(e);
                        }}
                        checked={isActive}
                        className={isActive ? "custom-switch-on" : ""}
                    />
                  </Col>
                </Form.Group>
              </section>

              <section>
                <hr/>
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    Hide Voucher
                  </Form.Label>
                  <Col sm={8}>
                    <Switch
                        onChange={(e) => {
                          setIsHidden(e);
                        }}
                        checked={isHidden}
                        className={isHidden ? "custom-switch-on" : ""}
                    />
                  </Col>
                </Form.Group>
              </section>

              <section>
                <div className="btn-footer-group">
                  <Link to={{pathname: `/voucher/index`}}>
                    <Button className="btn-cancel" variant="light">
                      Cancel
                    </Button>
                    {"  "}
                  </Link>
                  <Button
                      style={{width: 135, height: 46}}
                      className="btn"
                      variant="info"
                      type="submit"
                      form="voucherForm"
                  >
                    Save
                  </Button>
                </div>
              </section>
            </Form>
          </Card.Body>
        </Card>

        <AlertMessage
            {...alertProps}
            show={showAlert}
            onAlertCloseAction={onAlertClose}
        />
      </Grid>
    </div>
  );
};

export interface VoucherFormProps {
  onSubmit: (
      id: string,
      name: string,
      code: string,
      type: number,
      appliesMultiple: boolean,
      isMembership: boolean,
      roleIdList: string,
      membershipTierList: string,
      isEnableStore: boolean,
      startDate: Date,
      endDate: Date,
      discountValue: number,
      maximumAmount: number,
      isEntireOrder: boolean,
      isSpecificCollection: boolean,
    collectionListId: string,
    isSpecificProduct: boolean,
    productListId: string,
    isNoneMinimumRequirement: boolean,
    isMinimumPurchaseAmount: boolean,
    minimumPurchaseAmount: number,
    isMinimumQuantityOfItems: boolean,
    isNumberOfUsage: boolean,
    numberOfUsage: number,
    isLimitUsePerCustomer: boolean,
    isAllCity: boolean,
    isSelectedCity: boolean,
    selectedCity: string,
    isExcludeShipping: boolean,
    excludeShipping: number,
    customerGetAmountQty: number,
    anyItemFrom: number,
    isFree: boolean,
    isNominalCashback: boolean,
    nominalCashback: number,
    isMaximumAmountPercentage: boolean,
    maximumAmountPercentage: number,
    isUpto: boolean,
    upto: number,
    quota: number,
    maximumEstimatedStoreExpenses: number,
    discountMinimumRequirementType: number,
    image: string,
    isActive: boolean,
    isExpired: boolean,
    freeItemId: string | null,
    nominalCashbackType: number,
    minimumQuantityOfItems: number,
    valueType: number,
    productId: string | null,
    isHidden: boolean,
  ) => void;
  data: VoucherModel;
  isEdit: boolean;
}

export default VoucherForm;
