export interface MembershipTierTransaction {
    id: string;
    partnerId: string;
    partnerName: string;
    membershipTierId: string;
    membershipTier: string;
    status: MembershipStatusEnum;
    confirmationPaymentDate: string;
    image: string;
    expiredDate: string | null;
    paymentMethodId: string;
}

export interface MembershipTier {
    id: string;
    title: string;
    name: string;
    description: string;
    icon: string;
    price: number;
    pointMultiplier: number;
    isActive: boolean;
}

export interface GetMembershipTierTransactionRequest {
    skip: number;
    take: number;
}

export interface ConfirmMembershipTierPaymentRequest {
    id: string;
    confirmationPaymentDate: string;
    image: string;
}

export interface RejectMembershipTierPaymentRequest {
    id: string;
    note: string;
}

export enum MembershipStatusEnum
{
    WaitingForPayment, WaitingForConfirmation, Approved, Reject, Expired
}